import { useEffect } from 'react';
import { useStore } from '../../tools/zustand';
import { isInputFocused } from '../utils/domUtils';

const throttle = (callback: () => void, delay: number) => {
    let lastCall = 0;
    return () => {
        const now = new Date().getTime();
        if (now - lastCall < delay) return;
        lastCall = now;
        callback();
    };
};

/**
 * Custom hook to handle dynamic font size based on screen size.
 * Sizes we should support:
 * - Ultra Wide: 3440 x 1440
 * - HD: 1920 x 1080
 * - Full HD: 1920 x 1080
 * - Portrait: 1080 x 1920
 *
 * @returns {void}
 */
const useDynamicFontSize = () => {
    const isRaiseMenuOpen = useStore((state) => state.isRaiseMenuOpen);

    useEffect(() => {
        const updateFontSize = () => {
            if (isRaiseMenuOpen || isInputFocused()) return; // Skip font size update if raise menu is open or input is focused

            const width = window.innerWidth * window.devicePixelRatio;
            const height = window.innerHeight * window.devicePixelRatio;
            const aspectRatio = width / height;

            // Use the larger dimension for base font size calculation
            const largerDimension = Math.max(width, height);

            // Base divisor
            const baseDivisor = 100 * window.devicePixelRatio;

            // Adjust divisor for portrait orientation and smaller screens
            let adjustedDivisor = baseDivisor;
            if (aspectRatio < 1 || width < 768 * window.devicePixelRatio) {
                // Portrait breakpoint
                adjustedDivisor = baseDivisor * 0.7; // Decrease divisor to increase font size
            }

            let fontSize = largerDimension / adjustedDivisor;

            // Apply minimum and maximum font sizes.
            const minFontSize = 10;
            const maxFontSize = 32;
            fontSize = Math.max(minFontSize, Math.min(fontSize, maxFontSize));

            // console.log('-------------- FONT SIZE UPDATE --------------');
            // console.log(
            //     'App FontSize:',
            //     fontSize,
            //     'Dimensions:',
            //     width,
            //     height,
            // );
            // console.log('Aspect Ratio:', aspectRatio);
            // console.log('DPR:', window.devicePixelRatio);
            // console.log(
            //     'Adjusted Divisor:',
            //     adjustedDivisor,
            //     'Base Divisor:',
            //     baseDivisor,
            // );
            document.documentElement.style.fontSize = `${fontSize}px`;
        };

        const throttledUpdateFontSize = throttle(updateFontSize, 10);
        throttledUpdateFontSize();
        const intervalId = setInterval(
            throttledUpdateFontSize,
            1000,
        );

        const resizeObserver = new ResizeObserver(throttledUpdateFontSize);
        resizeObserver.observe(document.documentElement);

        return () => {
            resizeObserver.disconnect();
            clearInterval(intervalId);
        };
    }, [isRaiseMenuOpen]);
};

export default useDynamicFontSize;
