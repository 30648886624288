import React, { useEffect, useRef } from 'react';
import { useActiveAccount, useActiveWallet } from 'thirdweb/react';
import { useRouter } from 'next/router';
import { useIsAuthenticated } from './hooks/useIsAuthenticated';
import { useStore } from '../tools/zustand';
export const UserProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const account = useActiveAccount();
  const activeWallet = useActiveWallet();
  const setAccount = useStore(state => state.setAccount);
  const router = useRouter();
  const {
    isLoggedIn
  } = useIsAuthenticated();
  activeWallet?.subscribe('accountChanged', async account => {
    console.log('UserProvider: accountChanged', account);
    activeWallet.disconnect();
    setAccount(account.address);
  });
  const fetchUser = useStore(state => state.fetchUser);
  const isFetching = useRef(false);
  useEffect(() => {
    console.log('UserProvider: account', account, 'isFetching: ', isFetching.current);
    if (!account || isFetching.current) return;
    isFetching.current = true;
    fetchUser().finally(() => isFetching.current = false);
    isLoggedIn();
  }, [account?.address, account, router.pathname]);
  return <>{children}</>;
};
export default UserProvider;