import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { modalAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers([...modalAnatomy.keys]);

const baseStyle = definePartsStyle({
    header: {
        alignItems: 'center',
        bg: 'brand.lightGray',
        display: 'flex',
        justifyContent: 'space-between',
    },
    dialog: {
        bg: 'brand.primaryGray',
        paddingBottom: 'env(safe-area-inset-bottom)',
        paddingTop: 'env(safe-area-inset-top)',
        paddingLeft: 'env(safe-area-inset-left)',
        paddingRight: 'env(safe-area-inset-right)',
    },
    body: {
        alignItems: 'center',
        bg: 'brand.primaryGray',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        py: '1.875rem',
        textAlign: 'center',
    },
    closeButton: {
        color: 'white',
    },
    footer: {
        bg: 'brand.lightGray',
        display: 'flex',
        gap: '1.5rem',
        justifyContent: 'flex-end',
        padding: '0.75rem 1rem',
    },
});

const variants = {
    popup: definePartsStyle({
        body: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
            textAlign: 'start',
        },
    }),
};

export const Modal = defineMultiStyleConfig({ baseStyle, variants });
