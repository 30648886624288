
export const commonPrimary = {
    borderRadius: '0.25rem',
    color: 'brand.white80',
    background: 'brand.primaryBlue',
    _disabled: {
        opacity: '0.3',
        background: 'brand.darkestGray',
        borderColor: 'brand.white50',
    },
    _hover: {
        borderColor: 'brand.white50',
        textDecoration: 'underline',
        fontWeight: '800',
    },
}
