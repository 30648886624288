export const Progress = {
    baseStyle: {
        filledTrack: {
            bg: 'brand.vividOrange',
        },
        track: 'rgba(47, 51, 58, 1)',
    },
};

export default Progress;
