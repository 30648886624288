import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { UserProvider } from './UserProvider';
import { theme } from '../styles/theme';
import { ThirdwebProvider } from 'thirdweb/react';
import useDynamicFontSize from './hooks/useDynamicFontSize';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { blast, blastSepolia } from 'thirdweb/chains';
import { CCP_GAMING_GQL_ENDPOINTS } from '../tools/config';
export const apolloClient = new ApolloClient({
  link: ApolloLink.split(operation => operation.getContext().chainId === blast.id, CCP_GAMING_GQL_ENDPOINTS[blast.id], CCP_GAMING_GQL_ENDPOINTS[blastSepolia.id]),
  cache: new InMemoryCache()
});
const queryClient = new QueryClient();
export const AppShell = ({
  children
}: {
  children: React.ReactNode;
}) => {
  useDynamicFontSize();
  return <ThirdwebProvider data-sentry-element="ThirdwebProvider" data-sentry-component="AppShell" data-sentry-source-file="AppShell.tsx">
            <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="AppShell.tsx">
                <ChakraProvider theme={theme} data-sentry-element="ChakraProvider" data-sentry-source-file="AppShell.tsx">
                    <ApolloProvider client={apolloClient} data-sentry-element="ApolloProvider" data-sentry-source-file="AppShell.tsx">
                        <UserProvider data-sentry-element="UserProvider" data-sentry-source-file="AppShell.tsx">{children}</UserProvider>
                    </ApolloProvider>
                </ChakraProvider>
            </QueryClientProvider>
        </ThirdwebProvider>;
};
export default AppShell;