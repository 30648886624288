export const Link = {
    baseStyle: {
        color: 'white',
        margin: 0,
        padding: 0,
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
    sizes: {
        sm: {
            fontSize: '.75rem',
        },
        md: {
            fontSize: '1rem',
        },
        lg: {
            fontSize: '1.5rem',
        },
        xl: {
            fontSize: '2rem',
        },
    },
    variants: {
        homePageLink: {
            fontSize: '0.875rem',
            textAlign: 'left',
            color: 'var(--Secondary-Grey-01, #B9C2D4)',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            textStyle: 'nowrap',
        },

    },
};

export default Link;
