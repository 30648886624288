import '../styles/CardFlip.css';
import '../styles/globals.css';
import { useEffect } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { AppShell } from '../components/AppShell';
function MyApp({
  Component,
  pageProps
}: AppProps) {
  // Refresh the page if there's a new SW update
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        registration.addEventListener('updatefound', () => {
          const newWorker = registration.installing;
          newWorker?.addEventListener('statechange', () => {
            if (newWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                if (confirm('A new version of this app is available. Refresh now?')) {
                  window.location.reload();
                }
              }
            }
          });
        });
      });
    }
  }, []);
  return <>
            <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
                <title>CCP Gaming</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
            </Head>
            <AppShell data-sentry-element="AppShell" data-sentry-source-file="_app.tsx">
                <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
            </AppShell>
        </>;
}
export default MyApp;