export const NumberInput = {
    variants: {
        default: {
            field: {
                textColor: 'white',
                border: 'none',
                borderRadius: '0px',
                bg: 'brand.lightestGray',
                w: '92px',
                h: '2rem',
                paddingX: '0.75rem',
                _focusVisible: { outline: 'none' },
                _invalid: { outline: 'none' },
            },
        },
    },
    defaultProps: {
        variant: 'default',
    },
};

export default NumberInput;
