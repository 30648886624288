import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { drawerAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers([...drawerAnatomy.keys]);

const baseStyle = definePartsStyle({
    overlay: {
        bg: 'transparent',
    },
    dialog: {
        bg: 'brand.primaryGray',
        color: 'brand.accentWhite',
        paddingBottom: 'env(safe-area-inset-bottom)',
        paddingTop: 'env(safe-area-inset-top)',
        paddingLeft: 'env(safe-area-inset-left)',
        paddingRight: 'env(safe-area-inset-right)',
    },
});

const variants = {
    nested: definePartsStyle({
        dialogContainer: {
            height: 'calc(100% - 10rem)',
        },
        overlay: {
            height: 'calc(100% - 10rem)',
            bg: 'transparent',
        },
        dialog: {
            position: 'absolute',
            top: 'auto', // override the default chakra top value
            height: 'calc(100% - 10rem)',
            maxHeight: '60vh',
            width: '100%',
            margin: '0 auto',
            backgroundColor: 'brand.primaryGray',
            borderRadius: 'md',
            pointerEvents: 'auto',
        },
    }),
};

export const Drawer = defineMultiStyleConfig({ baseStyle, variants });
