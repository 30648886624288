export const Radio = {
    baseStyle: {
        control: {
            borderColor: 'brand.lightestGray',
            _checked: {
                bg: 'brand.primaryBlue',
                borderColor: 'brand.primaryBlue',
            },
        },
        label: {
            color: 'white',
        },
    },
};
export default Radio;
