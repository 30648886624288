import { useActiveAccount, useActiveWalletChain } from 'thirdweb/react';

import { useStore } from '../../tools/zustand';
import { AuthService } from '../../client';

/**
 * Checks if the user is authenticated
 */
export const useIsAuthenticated = () => {
    const activeAccount = useActiveAccount();
    const activeChain = useActiveWalletChain();
    const setIsAuthenticated = useStore((state) => state.updateIsAuthenticated);

    const isLoggedIn = async () => {
        return await AuthService.isLoggedIn({
            walletId: activeAccount?.address ?? '',
            chainId: activeChain?.id ?? 0,
        }).then(({ wallet_id, authenticated }) => {
            setIsAuthenticated(
                authenticated && wallet_id === activeAccount?.address,
            );
            return authenticated && wallet_id === activeAccount?.address;
        });
    };

    return { isLoggedIn };
};
