import { defineStyle } from '@chakra-ui/react';
import { commonPrimary } from './CommonStyles';

const commonModalPrimary = {
    ...commonPrimary,
    boxShadow: '2px 2px 3px 0px rgba(0, 0, 0, 0.50)',
    flex: '1',
    fontSize: '1rem',
    fontWeight: '700',
    lineHeight: 'normal',
    height: '2.5rem',
    px: '0.75rem',
    py: '0.7rem',
}

export const modalPrimary = defineStyle({
    ...commonModalPrimary,
    py: '0.7rem',
    minWidth: '8rem',
    width: '8rem',
});

export const modalPortraitPrimary = defineStyle({
    ...commonModalPrimary,
    height: '3.5rem',
    py: '1rem',
});

export const commonModalSecondary = {
    background: 'transparent',
    border: '0.125rem solid',
    borderColor: 'brand.textWhite',
    color: 'brand.textWhite',
    px: '0.75rem',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    _hover: {
        textDecoration: 'underline',
        fontWeight: '800',
    },
};

export const modalSecondary = defineStyle({
    ...commonModalSecondary,
    minWidth: '8rem',
    width: '8rem',
});

export const modalPortraitSecondary = defineStyle({
    ...commonModalSecondary,
    flex: '1',
    height: '3.5rem',
    px: '0.75rem',
});

export const modalBodyButton = defineStyle({
    alignItems: 'center',
    background: 'brand.gray50',
    border: '3px solid transparent',
    borderRadius: '0.25rem',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    gap: '.75rem',
    justifyContent: 'center',
    padding: '1.875rem 1.875rem',
    width: '14.0625rem',
    _hover: {
        border: '3px solid',
        borderColor: 'brand.primaryBlue',
    },
    _active: {
        backgroundColor: 'brand.primaryBlue',
    },
});

export const modalFooterSecondaryButton = defineStyle({
    background: 'transparent',
    border: '0.125rem solid',
    borderColor: 'brand.textWhite',
    borderRadius: '0.25rem',
    justifyContent: 'center',
    padding: '0.875rem 2.375rem',
    fontWeight: '700',
    width: '9.375rem',
    _hover: {
        textDecoration: 'underline',
        fontWeight: '800',
    },
});

export const modalFooterButton = defineStyle({
    background: 'brand.primaryBlue',
    border: '3px solid transparent',
    borderRadius: '0.25rem',
    justifyContent: 'center',
    padding: '0.875rem 2.375rem',
    width: '9.375rem',
    _hover: {
        textDecoration: 'underline',
        fontWeight: '800',
    },
});
