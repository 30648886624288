import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { commonPrimary } from './CommonStyles';
import {
    modalPrimary,
    modalPortraitPrimary,
    modalBodyButton,
    modalFooterButton,
    modalFooterSecondaryButton,
    modalPortraitSecondary,
    modalSecondary,
} from './modalButtonStyles';

const solid = defineStyle({
    background: 'brand.primaryBlue',
});

const primary = defineStyle({
    ...commonPrimary,
});

const primaryDark = defineStyle({
    background: 'brand.darkestGray',
    border: '0.125rem solid transparent',
    _hover: { border: '0.125rem solid brand.primaryBlue' },
});

const portraitPrimary = defineStyle({
    ...commonPrimary,
    height: '3.5rem',
    px: '2.25rem',
    fontWeight: '700',
    boxShadow: '2px 2px 3px 0px rgba(0, 0, 0, 0.50)',
    _disabled: {
        opacity: '0.3',
        background: 'brand.darkestGray',
        borderColor: 'brand.white50',
    },
});

const secondary = defineStyle({
    background: 'transparent',
    border: '0.125rem solid',
    borderColor: 'brand.textWhite',
    borderRadius: '0.25rem',
    color: 'brand.textWhite',
    _disabled: {
        opacity: '0.3',
    },
    _hover: {
        background: 'brand.ccpGray',
    },
    _active: {
        background: 'brand.ccpGray',
    },
    _focus: {
        boxShadow: 'outline',
    },
});

const noStyleButton = defineStyle({
    background: 'transparent',
    border: 'none',
});

const baseStyle = defineStyle({
    borderRadius: '0.25rem',
    boxSizing: 'border-box',
});

const tableActionButton = defineStyle({
    backgroundColor: 'brand.gray30',
    color: 'white',
    borderRadius: '0.25rem',
    padding: '.3125rem 0.75rem',
    height: '1.875rem',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    border: '1px solid transparent',
    _hover: {
        border: '1px solid',
        borderColor: 'brand.primaryBlue',
    },
    _active: {
        background: 'brand.primaryBlue',
    },
});

const tableSortButton = defineStyle({
    border: '1px solid',
    borderColor: 'brand.gray30',
    borderRadius: '0.125rem',
    padding: '0px 0.375rem',
    height: '1.75rem',
    width: '13.125rem',
    background: 'brand.gray30',
});

const hamburgerMenuButton = defineStyle({
    borderRadius: '100%',
    h: '3rem',
    w: '3rem',
    bg: 'brand.lightGray',
    _hover: {
        bg: 'brand.primaryBlue',
    },
    _active: {
        bg: 'brand.primaryBlue',
    },
});

const formButton = defineStyle({
    alignItems: 'center',
    background: 'brand.gray30',
    border: 'none',
    borderRadius: '0.125rem',
    color: 'white',
    display: 'inline-flex',
    flexShrink: 0,
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    minWidth: '6.125rem',
    maxHeight: '2rem',
    _hover: {
        bg: 'brand.gray20',
    },
});

const gameOptionButton = defineStyle({
    alignItems: 'center',
    background: 'brand.gray30',
    border: '0.125rem solid',
    borderColor: 'transparent',
    borderRadius: '0.25rem',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
    padding: '1.25rem .75rem',
    width: '5rem',
    _hover: {
        borderColor: 'brand.primaryBlue',
    },
    _active: {
        background: 'brand.primaryBlue',
    },
});

const connectWalletButton = defineStyle({
    bg: 'brand.primaryGray',
    color: 'white',
    display: 'inline-flex',
    padding: '1rem 2.1875rem',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '.5rem',
    borderRadius: 'md',
    _hover: {
        border: '0.125rem solid',
        borderColor: 'brand.primaryBlue',
    },
    _active: {
        bg: 'brand.primaryBlue',
    },
});

const commonTimerButton = {
    borderRadius: '0.25rem',
    background: 'brand.gray30',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '3px solid',
    borderColor: 'transparent',
    w: '100%',
    color: 'brand.textWhite',
    textAlign: 'center',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '0.75rem',
    _hover: {
        borderColor: 'brand.primaryBlue',
    },
    _active: {
        backgroundColor: 'brand.primaryBlue',
    },
};

const timerButton = defineStyle({
    ...commonTimerButton,
});

const portraitTimerButton = defineStyle({
    ...commonTimerButton,
    height: '3.5rem',
});

const menuButton = defineStyle({
    background: 'brand.gray30',
    border: '3px solid transparent',
    borderRadius: '0.25rem',
    color: 'brand.textWhite',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    _hover: {
        border: '3px solid',
        borderColor: 'brand.primaryBlue',
    },
    _active: {
        backgroundColor: 'brand.primaryBlue',
    },
});

const menuItem = defineStyle({
    bg: 'brand.mediumGray',
    color: 'white',
    fontSize: '1rem',
    textAlign: 'start',
    fontWeight: 'bold',
    height: '2rem',
    whiteSpace: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
});

const homePage = defineStyle({
    alignItems: 'center',
    background: 'rgba(24, 27, 32, 0.70)',
    border: '2px solid rgba(255, 255, 255, 0.80)',
    borderRadius: '4px',
    boxShadow: '0px 0px 8px 0px #394DFF',
    display: 'flex',
    flexShrink: 0,
    fontSize: 'clamp(16px, 1rem, 1.75rem)',
    gap: '1rem',
    height: '4rem',
    justifyContent: 'center',
    padding: '2rem 3rem',
    width: '14rem',
    _hover: {
        border: '2px solid',
        borderColor: 'brand.primaryBlue',
        borderRadius: '4px',
        background: 'brand.primaryBlue',
    },
    _focus: {
        borderColor: 'brand.primaryBlue',
        boxShadow: '0px 0px 3px 1px #4955EB',
    },
});

const portraitHomePage = defineStyle({
    color: 'white',
    px: '1.5rem',
    py: '1rem',
    fontSize: '1.75rem',
    fontWeight: '400',
    textDecoration: 'underline',
    w: '15rem',
    h: '5rem',
    borderRadius: '50px',
    border: '1px solid',
    borderColor: 'brand.primaryGray',
    background: 'brand.primaryGray',
    boxShadow: '0px 0px 8px 0px #394DFF',
    _hover: {
        border: '0.125rem solid',
        borderColor: 'brand.primaryBlue',
        boxShadow: '0px 0px 8px 0px #394DFF',
    },
    _active: {
        background: 'brand.primaryBlue',
    },
});

const chatButton = defineStyle({
    background: 'brand.chatGray',
    color: 'white',
    minHeight: '1.5625rem',
    height: '1.5625rem',
    minWidth: '1.5625rem',
    width: '1.5625rem',
    p: '0px',
    _hover: {
        background: 'brand.chatGray',
    },
});

const portraitPrimaryDark = defineStyle({
    background: 'brand.gray30',
    border: '0.125rem solid transparent',
    color: 'white',
    fontWeight: '700',
    px: '2.25rem',
    height: '3.5rem',
    boxShadow: '2px 2px 3px 0px rgba(0, 0, 0, 0.50)',
    _hover: { border: '0.125rem solid brand.primaryBlue' },
});

export const Button = defineStyleConfig({
    baseStyle,
    variants: {
        chatButton,
        connectWalletButton,
        formButton,
        gameOptionButton,
        hamburgerMenuButton,
        homePage,
        menuButton,
        menuItem,
        modalBodyButton,
        modalFooterButton,
        modalFooterSecondaryButton,
        modalPortraitPrimary,
        modalPortraitSecondary,
        modalPrimary,
        modalSecondary,
        noStyleButton,
        portraitHomePage,
        portraitPrimary,
        portraitPrimaryDark,
        portraitTimerButton,
        primary,
        primaryDark,
        secondary,
        solid,
        tableActionButton,
        tableSortButton,
        timerButton,
    },
});

export default Button;
