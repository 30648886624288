import { HttpLink } from '@apollo/client';
import { blast, blastSepolia } from 'thirdweb/chains';
import { Address } from 'viem';

export const THIRDWEB_PROJECT_ID = 'efee4470368da6215ee854fa9b62dbdc';
export const GOLDSKY_BASE_URL =
    'https://api.goldsky.com/api/public/project_cly14qj0l0wu501vnc6dq5fjv/subgraphs';

const GINZA_GAMING_CONTRACT_ADDRESS_BOOK: Record<number, Address> = {
    [blastSepolia.id]: '0xB6aB83d90feab2d35eCE592BC4e8a0D821715CAA',
    [blast.id]: '0x0',
};

const TOKEN_CONTRACT_ADDRESS_BOOK: Record<number, Address> = {
    [blastSepolia.id]: '0x4200000000000000000000000000000000000022',
    [blast.id]: '0x0',
};

export const CCP_GAMING_GQL_ENDPOINTS: Record<number, HttpLink> = {
    [blastSepolia.id]: new HttpLink({
        uri: `${GOLDSKY_BASE_URL}/ccp-blast-testnet-blast-sepolia/5.0.0/gn`,
    }),
    [blast.id]: new HttpLink({
        uri: `${GOLDSKY_BASE_URL}/ccp-blast-testnet-blast/2.0.0/gn`,
    }),
};

const USDB_ADDRESS_BOOK: Record<number, Address> = {
    [blastSepolia.id]: '0x4200000000000000000000000000000000000022',
    [blast.id]: '0x4300000000000000000000000000000000000003',
};

export { GINZA_GAMING_CONTRACT_ADDRESS_BOOK, TOKEN_CONTRACT_ADDRESS_BOOK, USDB_ADDRESS_BOOK };
